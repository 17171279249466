/**
 * External Dependencies
 */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Internal Components
 */
import SelectedNote from "./selectednote";
import UnselectedNote from "./unselectednote";
import Button from "../../shared/button";

/**
 * Internal Dependencies
 * - Styles
 * - Redux Actions
 * - Services and Utilities
 */
import "./framenotes.css";
import { useSelector, useDispatch } from "react-redux";
import {
  upsertUserStats,
  setCurrentState,
  setVisit,
  setNotification,
} from "../../../redux/actions";
import { generateId, getCurrentDate } from "../../../scripts/apiService";
import DarkButton from "../../shared/darkbutton";

/**
 * FrameNotes component for displaying and managing notes.
 * @param {object} props - The component props.
 * @param {string} props.rootClassName - The root class name for styling.
 * @returns {JSX.Element} The FrameNotes component.
 */
const FrameNotes = ({ rootClassName }) => {
  /**
   * @dispatch - Redux dispatch function to send actions to the store.
   * @currentVisitId - Current visit ID from the global state.
   * @currentNoteId - Current note ID from the global state.
   * @userData - User data from the global state.
   * @buttonIconSrc - State for button icon source.
   * @setButtonIconSrc - Function to update button icon source.
   * @noteListRef - Reference to the note list DOM element.
   * @currentVisit - Current visit based on the current visit ID.
   * @notes - Notes of the current visit.
   * @visitTypes - Visit types from the global state.
   * @isInitialLoad - State to track if it's the initial load.
   * @setIsInitialLoad - Function to update the initial load state.
   * @prevVisitTypeId - Previous visit type id
   */
  const dispatch = useDispatch();
  const currentVisitId = useSelector(
    (state) => state.globalData.currentState.currentVisitId,
  );
  const currentNoteId = useSelector(
    (state) => state.globalData.currentState.currentNoteId,
  );
  const userData = useSelector((state) => state.globalData.userData);
  const [buttonIconSrc, setButtonIconSrc] = useState("/icons/copy.svg");
  const noteListRef = useRef(null);
  const currentVisit =
    userData.visits?.find((visit) => visit._id === currentVisitId) || null;
  const notes = currentVisit?.notes || [];
  const visitTypes = useSelector(
    (state) => state.globalData.userData.visitTypes,
  );
  const prevVisitTypeId = useRef(currentVisit.visitTypeId);
  const [notificationDisplayed, setNotificationDisplayed] = useState(false);

  useEffect(() => {
    if (!currentVisit?.visitTypeUptoDate && !notificationDisplayed) {
      dispatch(
        setNotification({
          name: "Template Updated",
          description:
            "Click regenerate to get the latest template. Until then some AI features may not work.",
          status: "warning",
          duration: 10000,
          isClosable: true,
        }),
      );
      setNotificationDisplayed(true);
    }
  }, [currentVisit]);

  useEffect(() => {
    /**
     * Sends notification if the visitType was not found
     */
    if (
      !visitTypes.some(
        (visitType) => visitType._id === currentVisit.visitTypeId,
      )
    ) {
      dispatch(
        setNotification({
          name: "Template Not Found",
          description:
            "The template does not exist. Some functionality on this page may not work.",
          status: "warning",
          duration: 10000,
          isClosable: true,
        }),
      );
    }
  }, [visitTypes]);

  /**
   * Reloads notes based on the current visit type.
   */
  const reloadNotes = async () => {
    const visitType = visitTypes.find(
      (type) => type._id === currentVisit.visitTypeId,
    );

    if (visitType) {
      const isTranscriptInsufficient =
        currentVisit.visitTranscript.split(" ").length < 10;

      const newNotes = visitType.noteTypes.map((noteType) => ({
        _id: generateId(),
        noteName: noteType.noteTypeName,
        noteBody: isTranscriptInsufficient
          ? "[not enough data; insufficient transcript]"
          : "",
        noteTypeId: noteType._id,
        noteFirstTimeGenerated: !isTranscriptInsufficient,
        noteIsVisible: true,
      }));

      dispatch(
        setVisit(currentVisitId, { notes: newNotes, visitTypeUptoDate: true }),
      );
    }
  };

  useEffect(() => {
    /**
     * Reloads notes on visit type change.
     */
    if (prevVisitTypeId.current !== currentVisit.visitTypeId) {
      reloadNotes();
      prevVisitTypeId.current = currentVisit.visitTypeId;
    }
  }, [currentVisit.visitTypeId]);

  /**
   * Handles note click event.
   * @param {string} noteId - The ID of the clicked note.
   */
  const handleRegenerateClick = () => {
    reloadNotes();
  };

  /**
   * Handles note click event.
   * @param {string} noteId - The ID of the clicked note.
   */
  const handleNoteClick = async (noteId) => {
    dispatch(setCurrentState({ currentNoteId: noteId }));
  };

  /**
   * Handles copy all button click event.
   */
  const handleCopyAllClick = async () => {
    dispatch(
      setVisit(currentVisitId, {
        visitCopyMetric: (currentVisit.visitCopyMetric || 0) + 1,
      }),
    );

    dispatch(
      upsertUserStats({
        userStatsDate: getCurrentDate(),
        userStatsCopyMetric: 1,
      }),
    );

    try {
      const visitType = visitTypes.find(
        (type) => type._id === currentVisit.visitTypeId,
      );
      const clipboardText = notes
        .filter(
          (note) => note.noteIsVisible === undefined || note.noteIsVisible,
        )
        .map((note) => {
          if (visitType?.visitTypeBodyType === "TEMPLATE") {
            dispatch(
              setNotification({
                name: "Sync to Epic",
                description:
                  "Inside an Epic note, use Ctrl + Alt + V on Windows or Cmd + Option + V on macOS.",
                status: "info",
                duration: 7000,
                isClosable: true,
              }),
            );
            return `${note.noteBody}`;
          } else {
            return `${note.noteName.toUpperCase()}:\n${note.noteBody}`;
          }
        })
        .join("\n\n");
      if (clipboardText !== "") {
        await navigator.clipboard.writeText(
          clipboardText +
            "\n\nDisclaimer: This note was created with the help of Halo Medical Solutions, Inc. It is possible that typographical errors may have occurred. Any questions about content or clarifications should be directed to the medical provider.",
        );
      }
      setButtonIconSrc("/icons/checkmark.svg");
      setTimeout(() => {
        setButtonIconSrc("/icons/copy.svg");
      }, 2000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  useEffect(() => {
    /**
     * Handles click outside event to deselect note.
     * @param {object} event - The click event.
     */
    const handleClickOutside = async (event) => {
      if (noteListRef.current && !noteListRef.current.contains(event.target)) {
        dispatch(setCurrentState({ currentNoteId: "" }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [noteListRef]);

  return (
    <div className={`framenotes-framenotes ${rootClassName}`}>
      <div className="framenotes-notelist" ref={noteListRef}>
        {notes.map((note) =>
          note._id === currentNoteId ? (
            <SelectedNote
              key={note._id}
              id={note._id}
              title={note.noteName}
              body={note.noteBody}
              onClick={() => handleNoteClick("")}
              className=""
            />
          ) : (
            <UnselectedNote
              key={note._id}
              id={note._id}
              title={note.noteName}
              body={note.noteBody}
              onClick={() => handleNoteClick(note._id)}
              noteIsVisible={note.noteIsVisible}
              className=""
            />
          ),
        )}
      </div>
      <div className="framenotes-buttoncontainer">
        <Button
          text="Copy all"
          rootClassName="button-root-class-name1"
          className=""
          onClick={handleCopyAllClick}
          image={buttonIconSrc}
        />
        {!currentVisit.visitTypeUptoDate && (
          <DarkButton
            text="Regenerate"
            rootClassName="button-root-class-name1"
            className=""
            onClick={handleRegenerateClick}
            image="/icons/redo2.svg"
          />
        )}
      </div>
    </div>
  );
};

FrameNotes.defaultProps = {
  rootClassName: "",
};

FrameNotes.propTypes = {
  rootClassName: PropTypes.string,
};

export default FrameNotes;
